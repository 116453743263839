module.exports = {
  apiKey: '914c1f240d851d967f42eb124ee673e71a9131d3',
  apiUrl: 'https://api.moank.se/api',
  facebookAppId: '281136272349426',
  appUrl: 'https://app.moank.se',
  gaTrackingId: 'UA-76994925-1',
  gtmId: 'GTM-PHXNF36',
  kissmetricsKey: '85035b7ea9fb836fbaea45b310e109a3f6f1452a',
  sentryUrl: 'https://1069016f2014bdcf7196a3f02394e344@o111207.ingest.sentry.io/4505630226382853',
  environment: 'production',
};
